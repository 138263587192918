import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "../../../generic_components/DatePicker";

export const SecondDatePicker: view = ({
  dateEnd = observe.dateEnd,
  setDateEnd = update.dateEnd,
  dateStart = observe.dateStart,
  checkBoxValue = observe.checkBoxValue,
  setCheckBoxValue = update.checkBoxValue,
  setOpenSnackBar = update.openSnackBar,
  setLastButton = update.lastButton,
}) => {
  if (!checkBoxValue) {
    setDateEnd.set(dateStart);
  }

  const handleChange = (newValue: any) => {
    const day = newValue["$D"];
    const month = newValue["$M"];
    const year = newValue["$y"];
    const date = new Date(Date.UTC(year, month, day)).toISOString().split("T")[0];

    date < dateStart && checkBoxValue == true
      ? (setDateEnd.set(dateStart), setCheckBoxValue.set(false), setOpenSnackBar.set(true))
      : setDateEnd.set(date);

    setLastButton.set("QUICK ACTIONS");
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled={!checkBoxValue}
          value={dayjs(dateEnd)}
          views={["day"]}
          label="Choose end day"
          onChange={handleChange}
          format="DD-MM-YYYY"
          disableFuture={true}
        />
      </LocalizationProvider>
    </>
  );
};
