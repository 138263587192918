import * as React from "react";
import Typography from "@mui/material/Typography";
import { Tooltip, Zoom } from "@mui/material";
import { Twemoji } from "react-emoji-render";

export const MessageToolTip = ({ message }: any) => {
  const componentWithEmoji = (value: string) =>
    value && <Twemoji>{value}</Twemoji>;

  let slicedText;
  let isTooLong = false;
  const filteredMessage = message.replace(
    /:skin-tone-\d+:/g,
    ""
  );

  slicedText =
    filteredMessage.length > 20
      ? filteredMessage.slice(0, 20) + "..."
      : filteredMessage;

  if (slicedText !== filteredMessage) isTooLong = true;

  return (
    <div className="user-message">
      {isTooLong ? (
        <Tooltip
          // open={true}
          title={componentWithEmoji(filteredMessage)}
          placement="bottom-end"
          TransitionComponent={Zoom}
          TransitionProps={{ timeout: 200 }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "#526f74",
                fontSize: "1rem",
              },
            },
          }}
        >
          <Typography>
            {componentWithEmoji(slicedText)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography>
          {componentWithEmoji(slicedText)}
        </Typography>
      )}
    </div>
  );
};
