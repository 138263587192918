import { Twemoji } from "react-emoji-render";

export const PageContainer: view = ({ children }) => {
  return (
    <>
        
      <h1 className={"vacations-heading code11-font"}> Vacations Table <Twemoji> :palm_tree: </Twemoji></h1> 
      {children}
    </>
  );
};
