import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table as TableMui,
  TableRow,
  Typography,
} from "@mui/material";
import { MessageToolTip } from "../main_page/container/MessageToolTip";

const DataCell: view = ({ column, text }) => {
  return (
    <TableCell align="center">
      {column === "message" ? <MessageToolTip message={text} /> : <>{text}</>}
    </TableCell>
  );
};

const Row: view = ({
  id,
  columns,
  historyColumns,
  row,
  openHistory = observe.openHistory[arg.id],
  setOpenHistory = update.openHistory[param.id],
}: any) => {
  const [mainRow, ...historyRows] = row;

  const hasHistory = historyRows.length ? true : false;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} className="rowHead">
        <TableCell>
          {hasHistory && (
            <IconButton
              sx={{ padding: "0px" }}
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpenHistory.set(!openHistory, {
                  id,
                });
              }}
            >
              {openHistory ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {Object.keys(columns).map((column: any) => (
          <DataCell key={column} column={column} text={mainRow[column]} />
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openHistory} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <TableMui size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {Object.values(historyColumns).map((columnName: any) => (
                      <TableCell
                        key={columnName}
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyRows.map((row: any) => (
                    <TableRow key={historyRows.indexOf(row)}>
                      {Object.keys(historyColumns).map((column: any) => (
                        <DataCell key={column} column={column} text={row[column]} />
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </TableMui>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export const Table: view = ({ columns, historyColumns, rows }) => {
  return (
    <TableContainer component={Paper}>
      <TableMui aria-label="collapsible table">
        <TableHead>
          <TableRow className="tableHead">
            <TableCell />
            {Object.values(columns).map((columnName: any) => (
              <TableCell
                key={columnName}
                style={{
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: "bold",
                }}
                align="center"
              >
                {columnName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <Row
              key={`row${rows.indexOf(row)}`}
              id={`row${rows.indexOf(row)}`}
              row={row}
              columns={columns}
              historyColumns={historyColumns}
            />
          ))}
        </TableBody>
      </TableMui>
    </TableContainer>
  );
};
