import { CheckBox } from "./CheckBox";
import { FirstDatePicker } from "./FirstDatePicker";
import { SecondDatePicker } from "./SecondDatePicker";

export const DateRange: view = () => {
  return (
    <>
      <FirstDatePicker />
      <CheckBox />
      <SecondDatePicker />
    </>
  );
};
