import axios from "axios";

const baseUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/api"
    : "/api";

export const getUserActivity: producer = async ({
  users = observe.users,
  dateStart = get.dateStart.value(),
  dateEnd = get.dateEnd.value(),
  setActivity = update.activity,
}) => {
  const { username, password } = JSON.parse(
    localStorage.getItem("auth") ||
      JSON.stringify({ user: "", password: "" })
  );

  let activity: any = {};

  for (const userIndex in users) {
    const _user = users[userIndex];

    const query = {
      startDate: dateStart,
      finalDate: dateEnd,
    };

    const payload = {
      method: "GET",
      url: `${baseUrl}/users/activity/${_user.userId}`,
      params: query,
      auth: { username, password },
    };

    const { data: userActivity } = await axios(payload);

    activity[_user.userId] =
      userActivity.shortPrettyTotalIn;
  }

  setActivity.set(activity);
};
