import { CSVLink, CSVDownload } from "react-csv";
import { Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { User } from "../../Types/User";

export const CsvDownload: view = ({
  filteredUsers = observe.filteredUsers,
}) => {
  const formatedUsers: Array<User> = [];
  filteredUsers.forEach((user: User) => {
    user.data.forEach((_data: any) => {
      const { _id, data, ...formatUser } = user;
      formatedUsers.push({ ...formatUser, ..._data });
    });
  });

  return (
    <>
      <CSVLink data={formatedUsers}>
        <Button
          sx={{ borderColor: "#c4c4c4", color: "#39abbd" }}
          color="success"
          variant="outlined"
        >
          <SaveAltIcon
            sx={{ marginRight: "7px" }}
            fontSize="small"
          />
          Download csv
        </Button>
      </CSVLink>
    </>
  );
};
