import { engine } from "@c11/engine.runtime";
import { render } from "@c11/engine.react";
import { App } from "./App";
import "./global";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
} from "react-router-dom";

const app = engine({
  state: {
    users: [],
    filteredUsers: [],
    textInput: "",
    activity: [],
    openHistory: {},
    dateStart: new Date().toISOString().split("T")[0],
    dateEnd: new Date().toISOString().split("T")[0],
    checkBoxValue: false,
    openSnackBar: false,
    openMenu: null,
    lastButton: "QUICK ACTIONS",
    switchValue: false
  },
  use: [
    render(
      <Router>
        <App />
      </Router>,
      "#app"
    ),
  ],
});

app.start();

// Each renderer can have a state where it stores if it finished rendering/mounting/etc
// This can be used to hook-up processes for export for example
