import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";

export const DateMenu: view = ({
  setDateStart = update.dateStart,
  setDateEnd = update.dateEnd,
  setCheckBoxValue = update.checkBoxValue,
  openMenu = observe.openMenu,
  setOpenMenu = update.openMenu,
  lastButton = observe.lastButton,
  setLastButton = update.lastButton,
}) => {
  const today = new Date();
  const dateToday = today.toISOString().split("T")[0];
  const yesterday =  new Date();
  yesterday.setDate(today.getDate()-1);
  const dateYesterday = yesterday.toISOString().split("T")[0];


  const firstDayOfWeek = new Date();
  firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
  const dateFirstDayOfWeek = firstDayOfWeek.toISOString().split("T")[0];

  const dateFirstDayOfMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1 + 1
  )
    .toISOString()
    .split("T")[0];

  const open = Boolean(openMenu);

  return (
    <Box>
      <Button
        sx={{
          borderColor: "#c4c4c4",
          color: "#39abbd",
          width: "150px",
          height: "60px",
        }}
        variant="outlined"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => setOpenMenu.set(e.currentTarget)}
      >
        {lastButton}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={openMenu}
        open={open}
        onClose={() => setOpenMenu.set(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setCheckBoxValue.set(false);
            setDateStart.set(today);
            setOpenMenu.set(null);
            setLastButton.set("Day");
          }}
        >
          Current Day
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCheckBoxValue.set(false);
            setDateStart.set(dateYesterday);
            setOpenMenu.set(null);
            setLastButton.set("Day");
          }}
        >
          Last Day
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCheckBoxValue.set(true);
            setDateEnd.set(dateToday);
            setDateStart.set(dateFirstDayOfWeek);
            setOpenMenu.set(null);
            setLastButton.set("Week");
          }}
        >
          Last Week
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCheckBoxValue.set(true);
            setDateEnd.set(dateToday);
            setDateStart.set(dateFirstDayOfMonth);
            setOpenMenu.set(null);
            setLastButton.set("Month");
          }}
        >
          Last Month
        </MenuItem>
      </Menu>
    </Box>
  );
};
