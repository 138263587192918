import { TextField } from "@mui/material";

export const InputField: view = ({
  label,
  variant,
  onChange,
}: any) => {
  return (
    <TextField
      label={label}
      variant={variant}
      onChange={onChange}
    />
  );
};
