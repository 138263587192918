import { Box } from "@mui/material";
import { CustomizedSnackbars } from "./dates/CustomizedSnackbars";
import { DateMenu } from "./dates/DateMenu";
import { DateRange } from "./dates/DateRange";
import { UsernameInput } from "./UsernameInput";
import { CsvDownload } from "./CsvDownload";
import * as producers from "../../../Producers";

export const PageContainer: view = ({ children }) => {
  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          marginBottom: "1rem",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <DateMenu />
        <DateRange />
        <UsernameInput />
        <CsvDownload />
      </Box>
      {children}
      <CustomizedSnackbars />
    </>
  );
};

PageContainer.producers(producers);
