import "./styles/index.css";
import { AuthorizationView } from "./Views/authorization/AuthorizationView";
import { Switch } from "./Views/generic_components/Switch";
import { Users } from "./Views/main_page";
import { Vacation } from "./Views/vacations_page";
import { Box } from "@mui/material";

export const App: view = ({
  authRedirect = observe.authRedirect, // ?
  switchValue = observe.switchValue,
}) => {
  const isAuth = localStorage.getItem("auth");

  console.log(switchValue);

  return (
    <>
      {isAuth ? (
        <>
          <Switch />
          <Box
            style={{
              maxWidth: "70%",
              margin: "auto",
              marginTop: "2rem",
            }}
          >
            {switchValue ? <Vacation /> : <Users />}
          </Box>
        </>
      ) : (
        <AuthorizationView />
      )}
    </>
  );
};
