import {
  Checkbox as CheckboxMui,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
const theme = createTheme({
  palette: {
    primary: {
      main: "#39ABBD",
    },
  },
});
export const CheckBox: view = ({
  setCheckBox = update.checkBoxValue,
  checkBoxValue = observe.checkBoxValue,
  dateStart = observe.dateStart,
  setDateEnd = update.dateEnd,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBox.set(event.target.checked);
    if (checkBoxValue == false) setDateEnd.set(dateStart);
  };
  return (
    <>
      <div style={{ display: "flex" }}>
        <p style={{ fontFamily: "Montserrat", fontWeight: "bold" }}>UNTIL </p>
        <ThemeProvider theme={theme}>
          <CheckboxMui
            color="primary"
            onChange={handleChange}
            checked={checkBoxValue}
          />
        </ThemeProvider>
      </div>
    </>
  );
};
