import * as React from "react";
import { Table } from "../generic_components/Table";
import dayjs from "dayjs";

export const VacationTable: view = ({}) => {
  const columns = {
    userName: "Name",
    vacationStart: "From",
    vacationEnd: "To",
  };

  const rows = [
    [
      {
        userName: "Ana",
        vacationStart: "10/10/10",
        vacationEnd: "14/10/10",
      },
    ],
    [
      {
        userName: "Anabel",
        vacationStart: "15/165/15",
        vacationEnd: "14/14120/143210",
      },
    ],
    [
      {
        userName: "Anisoara",
        vacationStart: "13430/112430/10",
        vacationEnd: "13/1560/610",
      },
    ],
  ];

  const historyColumns = {
    userName: "Name",
    vacationStart: "From",
    vacationEnd: "To",
  };

  return <Table columns={columns} historyColumns={historyColumns} rows={rows} />;
};
