import dayjs from "dayjs";
import { DatePicker } from "../../../generic_components/DatePicker";

export const FirstDatePicker: view = ({
  dateStart = observe.dateStart,
  setDateStart = update.dateStart,
  dateEnd = observe.dateEnd,
  checkBoxValue = observe.checkBoxValue,
  setCheckBoxValue = update.checkBoxValue,
  setOpenSnackBar = update.openSnackBar,
  setLastButton = update.lastButton,
}) => {
  const handleChange = (newValue: any) => {
    const day = newValue["$D"];
    const month = newValue["$M"];
    const year = newValue["$y"];
    const date = new Date(Date.UTC(year, month, day)).toISOString().split("T")[0];

    date > dateEnd && checkBoxValue == true
      ? (setDateStart.set(dateEnd), setCheckBoxValue.set(false), setOpenSnackBar.set(true))
      : setDateStart.set(date);

    setLastButton.set("QUICK ACTIONS");
  };

  return (
    <DatePicker
      views={["day"]}
      label="Choose start day"
      value={dayjs(dateStart)}
      onChange={handleChange}
      format="DD-MM-YYYY"
      disabled={false}
      disableFuture={true}
    />
  );
};
