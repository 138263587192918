import axios from "axios";

const baseUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/api"
    : "/api";

export const getUsers: producer = async ({
  dateStart = observe.dateStart,
  setUsers = update.users,
  dateEnd = observe.dateEnd,
  checkBoxValue = observe.checkBoxValue,
}) => {
  let date = {
    startDate: dateStart,
    finalDate: dateStart,
  };

  if (checkBoxValue) date.finalDate = dateEnd;

  const { username, password } = JSON.parse(
    localStorage.getItem("auth") || JSON.stringify({ user: "", password: "" })
  );

  const payload = {
    method: "GET",
    url: `${baseUrl}/users`,
    params: {
      startDate: date.startDate,
      finalDate: date.finalDate,
    },
    auth: { username, password },
  };

  const { data: _users } = await axios(payload);

  const sortbyName = (a: any, b: any) => {
    if (a.userName < b.userName) {
      return -1;
    }
    if (a.userName > b.userName) {
      return 1;
    }
    return 0;
  };

  const sortedUsers = _users.sort(sortbyName);

  setUsers.set(sortedUsers);
};
