import { ChangeEvent } from "react";

import * as AuthProducers from "./Authorization.producers";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AdbIcon from "@mui/icons-material/Adb";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Button from "@mui/material/Button";

import image from "../../assets/logo.jpg"

export const AuthorizationView: view = ({
  username = update.auth["username"],
  password = update.auth["password"],
  triggerAuth = update.auth["requestAuth"],
}) => {
  const userHandler = ({ target }: ChangeEvent<HTMLInputElement>) =>
    username.set(target.value);
  const passwordHandler = ({ target }: ChangeEvent<HTMLInputElement>) =>
    password.set(target.value);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginTop: "100px",
      }}
    >
      <img src={image} style={{ maxHeight: "200px", maxWidth: "200px" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "flex-end", marginTop: "10px" }}
        >
          <AdbIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            label="User"
            variant="standard"
            onChange={userHandler}
          />
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "flex-end", marginTop: "10px" }}
        >
          <VpnKeyIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            type="password"
            label="Password"
            variant="standard"
            onChange={passwordHandler}
          />
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{ borderRadius: "20px", margin: "50px" }}
          onClick={() => triggerAuth.set(Date.now())}
        >
          {"Login >"}
        </Button>
      </Box>
    </Box>
  );
};

AuthorizationView.producers(AuthProducers);
