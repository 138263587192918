import { InputField } from "../../generic_components/InputField";

export const UsernameInput: view = ({
  updateTextInput = update.textInput,
}) => {
  const handleChange = (event: any) => {
    updateTextInput.set(event.target.value);
  };

  return (
    <InputField
      label="Find username"
      variant="outlined"
      onChange={handleChange}
    />
  );
};
