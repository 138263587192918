import axios from "axios";

const baseUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/api"
    : "/api";

const Auth: producer = async ({
  triggerAuth = observe.auth["requestAuth"],
  credentials = get.auth,
  resetCredentials = update.auth,
  authRedirect = update.authRedirect,
  error = update.error["auth"],
}) => {
  if (!triggerAuth) return;

  const { username, password } = credentials.value();

  const payload = {
    method: "GET",
    url: `${baseUrl}/auth`,
    auth: {
      username,
      password,
    },
  };

  try {
    const { response } = (await axios(payload)).data;

    if (response === 1) {
      localStorage.setItem("auth", JSON.stringify({ username, password }));
      authRedirect.set(Date.now());
    }
  } catch (e) {
    console.log(e);
  }

  resetCredentials.set(null);
};

export default [Auth];
