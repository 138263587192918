import * as React from "react";
import { Table } from "../generic_components/Table";
import dayjs from "dayjs";

export const UsersTable: view = ({
  filteredUsers = observe.filteredUsers,
  activity = observe.activity,
}) => {
  const columns = {
    userName: "Name",
    createdAt: "Date",
    status: "Status",
    hour: "Input time",
    message: "Message",
    activity: "Activity",
  };

  const historyColumns = {
    userName: "Name",
    createdAt: "Date",
    message: "Message",
    status: "Status",
    hour: "Input time",
  };

  const rows = filteredUsers.map((user: any) => {
    const userData = user.data.map((status: any) => {
      const _createdAt = dayjs(status.createdAt).format("DD.MM.YYYY");
      return {
        ...status,
        createdAt: _createdAt,
        userName: user.userName,
        activity: activity[user.userId],
      };
    });

    return userData;
  });

  console.log("rows", rows)

  return <Table columns={columns} historyColumns={historyColumns} rows={rows} />;
};
