const formatString = (str: string) => {
  return str.toLowerCase().trim();
};

export const filterUsers: producer = ({
  users = observe.users,
  textInput = observe.textInput,
  filteredUsers = update.filteredUsers,
}) => {
  if (!users) return;

  const _filteredUsers = users.filter((user: any) =>
    formatString(user.userName).includes(
      formatString(textInput)
    )
  );

  filteredUsers.set(_filteredUsers);
};
