import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const DatePickerTheme = (theme: any) =>
  createTheme({
    ...theme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: "#434343",
            borderRadius: 5,
            borderWidth: 2,
            borderColor: "#e91e63",
            border: "1px solid",
            backgroundColor: "#a4cfd6",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "#39abbd",
            width: "10rem",
          },
        },
      },
    },
  });

export const DatePicker: view = ({
  views,
  label,
  value,
  onChange,
  format,
  disabled,
  disableFuture,
}) => {
  return (
    <ThemeProvider theme={DatePickerTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePickerMui
          views={views}
          label={label}
          value={value}
          onChange={onChange}
          format={format}
          disabled={disabled}
          disableFuture={disableFuture}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
